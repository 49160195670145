<template>
    <el-dialog title="关联" width="40%" :close-on-click-modal="false" append-to-body :visible.sync="visible">
        <el-form class="relevanceForm" size="small" :model="inputForm" ref="inputForm" :rules="dataRule"
                 v-loading="loading"
                 label-width="100px">
            <el-form-item label="案卷：" prop="caseFileArchivesBasicDataName">
                <el-input v-model.trim="inputForm.caseFileArchivesBasicDataName" disabled></el-input>
            </el-form-item>
            <el-form-item prop="caseFileArchivesBasicId" label-width="10px">
                <el-select v-model="inputForm.caseFileArchivesBasicId" placeholder="请选择" filterable>
                    <el-option v-for="item in caseFileArchivesBasicOpt" :key="item.id"
                               :label="item.basicName"
                               :value="item.archivesBasicId"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="卷内：" prop="withinTheVolumeArchivesBasicDataId">
                <el-select v-model="inputForm.withinTheVolumeArchivesBasicDataId" placeholder="请选择" filterable
                           @change="changeWithin">
                    <el-option v-for="item in withinTheVolumeArchivesBasicDataOpt" :key="item.id"
                               :label="item.archivesBasicName"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="withinTheVolumeArchivesBasicId" label-width="10px">
                <el-select v-model="inputForm.withinTheVolumeArchivesBasicId" placeholder="请选择" filterable>
                    <el-option v-for="item in withinTheVolumeArchivesBasicOpt" :key="item.id"
                               :label="item.basicName"
                               :value="item.archivesBasicId"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer text_center">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                loading: false,
                info: {},
                inputForm: {
                    caseFileArchivesBasicDataName: '',
                    caseFileArchivesBasicDataId: '',
                    caseFileArchivesBasicId: '',
                    withinTheVolumeArchivesBasicDataId: '',
                    withinTheVolumeArchivesBasicId: '',
                },
                caseFileArchivesBasicOpt: [],
                withinTheVolumeArchivesBasicDataOpt: [],
                withinTheVolumeArchivesBasicOpt: [],
                dataRule: {
                    caseFileArchivesBasicDataName: [
                        {required: true, message: '案卷不能为空', trigger: 'change'}
                    ],
                    caseFileArchivesBasicId: [
                        {required: true, message: '案卷字段不能为空', trigger: 'change'}
                    ],
                    withinTheVolumeArchivesBasicDataId: [
                        {required: true, message: '卷内不能为空', trigger: 'change'}
                    ],
                    withinTheVolumeArchivesBasicId: [
                        {required: true, message: '卷内字段不能为空', trigger: 'change'}
                    ]
                },
                fixedField: [],
            }
        },
        methods: {
            init(row) {
                this.info = row
                this.loading = false
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    this.getFixedField()
                })
            },
            // 获取关联信息
            getInfo() {
                this.loading = true
                this.$axios(this.api.record.archivesbasicdatacasefile + '/' + this.info.id).then(data => {
                    if (data.status) {
                        this.inputForm = this.recover(this.inputForm, data.data)
                        this.inputForm.caseFileArchivesBasicDataId = this.info.id
                        this.inputForm.caseFileArchivesBasicDataName = this.info.archivesBasicName
                        this.getField(this.info.id)
                        this.getWithinOpt()
                    }
                    this.loading = false
                })
            },
            // 获取固定字段
            getFixedField() {
                this.$axios(this.api.record.getSysSelectArchivesBasic).then(data => {
                    if (data.status) {
                        this.fixedField = data.data
                        this.getInfo()
                    }
                })
            },
            // 获取卷内数据
            getWithinOpt() {
                this.loading = true
                this.withinTheVolumeArchivesBasicOpt = []
                this.$axios(this.api.record.archivesbasicdata, {archivesBasicDataId: this.info.id, archivesBasicDataType: '卷内库'}).then(data => {
                    if (data.status) {
                        this.withinTheVolumeArchivesBasicDataOpt = data.data
                        if (this.inputForm.withinTheVolumeArchivesBasicDataId) {
                            let item = this.withinTheVolumeArchivesBasicDataOpt.filter(v => v.id == this.inputForm.withinTheVolumeArchivesBasicDataId)[0]
                            this.withinTheVolumeArchivesBasicOpt = this.fixedField.concat(item.archivesBasicDataGroup).filter(v => v.archivesBasicTypeCode == 'text')
                        }
                        this.loading = false
                    }
                })
            },
            // 选择卷内
            changeWithin(val) {
                this.inputForm.withinTheVolumeArchivesBasicId = ''
                let item = this.withinTheVolumeArchivesBasicDataOpt.filter(v => v.id == val)[0]
                this.withinTheVolumeArchivesBasicOpt = this.fixedField.concat(item.archivesBasicDataGroup).filter(v => v.archivesBasicTypeCode == 'text')
            },
            // 获取案卷字段
            getField(id) {
                this.loading = true
                this.$axios(this.api.record.basicdataGetById + '/' + id).then(data => {
                    if (data.status) {
                        this.caseFileArchivesBasicOpt = this.fixedField.concat(data.data.archivesBasicDataGroup).filter(v => v.archivesBasicTypeCode == 'text')
                    }
                    this.loading = false
                })
            },
            // 提交
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$axios(this.api.record.archivesbasicdatacasefileSave, this.inputForm, 'post').then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(data.msg)
                            }
                        })
                    }
                })
            },
        },
    }
</script>

<style scoped lang="scss">
    .relevanceForm {
        .el-form-item--small.el-form-item {
            display: inline-block;
            width: 50%;
        }
    }
</style>
