<template>
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
                 label-width="100px" @keyup.enter.native="search()">
            <el-form-item prop="name" label="输入搜索：">
                <el-input v-model.trim="searchForm.name" maxlength="50" placeholder="请输入模板名称/简介搜索"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="basicId" label="元数据：">
                <el-select v-model="searchForm.basicId" placeholder="请选择元数据" filterable>
                    <el-option v-for="item in archivesBasicOpt" :key="item.id" :label="item.basicName"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="archivesBasicDataType" label="档案类型：">
                <el-select v-model="searchForm.archivesBasicDataType" placeholder="请选择档案类型">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('archivesBasicDataType')">
                    </el-option>
                </el-select>
            </el-form-item>
            <div class="flex_b_c p_b2">
                <div>
                    <el-button size="small" type="primary" @click="search()" icon="el-icon-search">查询</el-button>
                    <el-button size="small" icon="el-icon-refresh-right" @click="reset()">重置</el-button>
                </div>
                <el-button class="f_r" type="primary" icon="el-icon-plus" size="small"
                           v-show="hasPermission('metadata:templateList:add')"
                           @click="addEdit(null, 'add')">新增
                </el-button>
            </div>
        </el-form>
        <div class="bg-white" style="height: calc(100vh - 255px);">
            <vxe-table
                    resizable
                    ref="xTree"
                    border="inner"
                    auto-resize
                    height="auto"
                    row-id="id"
                    size="small"
                    show-header-overflow
                    show-overflow
                    highlight-hover-row
                    :print-config="{}"
                    :export-config="{}"
                    :tree-config="{}"
                    v-loading="loading"
                    :checkbox-config="{checkStrictly: true}"
                    :data="dataList">
                <vxe-table-column title="模板名称" field="archivesBasicName" tree-node></vxe-table-column>
                <vxe-table-column title="档案类型" field="archivesBasicDataType"></vxe-table-column>
                <vxe-table-column title="简介" field="remakes"></vxe-table-column>
                <vxe-table-column title="操作" width="190px" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-show="hasPermission('metadata:templateList:view')"
                                   @click="addEdit(scope.row, 'view')">详情
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermission('metadata:templateList:edit')"
                                   @click="addEdit(scope.row, 'edit')">修改
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermission('metadata:templateList:relevance') && scope.row.archivesBasicDataType == '案卷库'"
                                   @click="relevance(scope.row, 'edit')">关联
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermission('metadata:templateList:delete')"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                    </template>
                </vxe-table-column>
            </vxe-table>
        </div>
        <TemplateListForm ref="templateListForm" @refreshDataList="search()"></TemplateListForm>
        <RelevanceForm ref="relevanceForm" @refreshDataList="search()"></RelevanceForm>
    </div>
</template>

<script>
    import TemplateListForm from './templateListForm'
    import RelevanceForm from './relevanceForm'
    import XEUtils from 'xe-utils'

    export default {
        components: {
            TemplateListForm, RelevanceForm
        },
        data() {
            return {
                searchForm: {
                    name: '',
                    basicId: '',
                    archivesBasicDataType: '',
                },
                archivesBasicOpt: [],
                loading: false,
                dataList: [],
            }
        },
        mounted() {
            this.getArchivesBasicOpt()
            this.search()
        },
        methods: {
            // 查询
            search() {
                this.loading = true
                this.$axios(this.api.record.selectArchivesBasicData, {
                    basicId: this.searchForm.basicId,
                    archivesBasicDataType: this.searchForm.archivesBasicDataType,
                }).then((res) => {
                    if (res.status) {
                        this.dataList = res.data
                        this.filterSearch()
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 获取元数据名称选项
            getArchivesBasicOpt() {
                this.$axios(this.api.record.selectAllArchivesBasic).then((res) => {
                    if (res.status) {
                        this.archivesBasicOpt = res.data
                    }
                })
            },
            // 过滤
            filterSearch() {
                if (this.searchForm.name) {
                    let options = {children: 'children'}
                    let searchProps = ['archivesBasicName', 'remakes']
                    this.dataList = XEUtils.searchTree(this.dataList, item => searchProps.some(key => XEUtils.toValueString(item[key]).indexOf(this.searchForm.name) > -1), options)
                    this.$nextTick(() => {
                        this.$refs.xTree.setAllTreeExpand(true)
                    })
                }
            },
            // 关联
            relevance(row) {
                this.$refs.relevanceForm.init(row)
            },
            // 新增
            addEdit(row, method) {
                this.$refs.templateListForm.init(row, method)
            },
            // 删除
            del(id) {
                // 校验是否被使用
                this.$axios(this.api.record.tepCheckRemoveById + '/' + id, {}, 'delete').then(data => {
                    if (!data.status) {
                        const h = this.$createElement;
                        this.$msgbox({
                            title: '提示',
                            message: h('p', null, [
                                h('p', {style: 'color: #000000; margin-bottom: 5px'}, '不支持删除元数据模板，已关联档案数据！'),
                                h('p', {style: 'color: #999999; font-size: 12px'}, '删除元数据模板，关联档案数据将同步删除。')
                            ]),
                            showCancelButton: true,
                            confirmButtonText: '坚持删除',
                            cancelButtonText: '取消',
                        }).then(() => {
                            this.delData(id)
                        });
                    } else {
                        this.$confirm(`确定删除所选项吗?`, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.delData(id)
                        })
                    }
                })
            },
            // 删除数据
            delData(id) {
                this.$axios(this.api.record.basicdataRemoveById + '/' + id, {}, 'delete').then(data => {
                    if (data.status) {
                        this.$message.success('删除成功')
                        this.search()
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            //重置
            reset() {
                this.$refs.searchForm.resetFields();
                this.search()
            },
        }
    }
</script>

<style scoped>

</style>
